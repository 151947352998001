<template>
  <div v-if="bio_links.length > 0">
    <div class="child_item "
         v-for="(link, index) in bio_links">
      <tr class="flex border-b items-center justify-center">
        <td class="py-3 text-left pl-3 w-[12.5%]">
          <div class="flex justify-start items-center gap-x-2">
            <template v-if="bio_links.length > 0">
                          <span
                            @click.prevent=""
                            data-toggle="collapse" :data-parent="'#'+index"
                            :data-target="'#'+link._id" v-tooltip="'View content sections'"
                            class="collapse_icon !cursor-pointer">
                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                  <path d="M1 1L5 5L9 1" stroke="#757A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                          </span>
            </template>
            <div class="content">
              <div class="">
                {{ link.page_title }}
              </div>
              <div class="clearfix"></div>
              <div class="url"
                   v-if="link.state === 'active'">
                <template>
                  <a :href="'https://' + link.shorten_domain + '/' + link.shorten_url"
                     target="_blank"
                     class="external_link">
                    https://{{ link.shorten_domain }}/{{ link.shorten_url }}
                  </a>
                </template>
              </div>
            </div>
          </div>
        </td>
        <td class="py-4 text-center w-[12.5%]"></td>
        <td class="py-4 text-center w-[12.5%]">
          <p class="font-poppins text-[12px]" >
            {{ commaSeparatedNumber(getLinkTotalVisitors(link)) }}
          </p>
        </td>
        <td class="py-4 text-center w-[12.5%]">
          <p class="font-poppins text-[12px]">
          {{ commaSeparatedNumber(getLinkUniqueVisitors(link)) }}
          </p>
        </td>
        <td class="py-4 text-center w-[12.5%]">
          <p>-</p>
        </td>
        <td class="py-4 text-center w-[12.5%]">
          <p>-</p>
        </td>
        <td class="py-4 text-center w-[12.5%]">
          <p class="font-poppins text-[12px]">{{
              link.created_at |
                relativeCaptilize
            }}</p>
        </td>
        <td class="py-4 text-right pr-4 w-[12.5%]"></td>
      </tr>
      <div :id="link._id" class="collapse child_row last_child">
        <div class="child_item "
             v-for="content in link.bio_content">
          <tr class="flex border-b items-center justify-center ">
            <td class="py-3 text-left w-[12.5%]">
            </td>
            <td  class="py-4 text-left pl-4 w-[12.5%]">
              <div class="content">
                <div class="name">
                  <span v-if="content.show_title">{{ content.title }} | </span>
                  <span style="text-transform: capitalize">{{ content.type }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="url" v-for="social_link in content.channels"
                     v-if="content.type === 'social' && social_link">
                  <template>
                      <p class="font-poppins text-[12px] flex">
                          <a :href="social_link"
                             target="_blank"
                             class="external_bio_link">{{ social_link }}
                          </a>
                      </p>
                  </template>
                </div>
                <div class="url" v-for="social_content in content.links"
                     v-if="content.type === 'links' && social_content">
                  <template>
                                            <span class="d-flex" v-tooltip="social_content.title">
                                                <span
                                                  class="d-inline-block truncate_url">{{ social_content.url }}</span>
                                                <a :href="social_content.url"
                                                   target="_blank"
                                                   class="external_bio_link">
                                                    <i class="far fa-link"></i>
                                                </a>
                                            </span>
                  </template>
                </div>
                <div class="url" v-for="social_content in content.posts"
                     v-if="content.type === 'rss' && social_content">
                  <template>
                                            <span class="d-flex" v-tooltip="social_content.title">
                                                <span
                                                  class="d-inline-block truncate_url">{{ social_content.link }}</span>
                                                <a :href="social_content.link"
                                                   target="_blank"
                                                   class="external_bio_link">
                                                    <i class="far fa-link"></i>
                                                </a>
                                            </span>
                  </template>
                </div>
              </div>
            </td>
            <td class="py-4 text-center w-[12.5%] text-[12px]">
              {{ commaSeparatedNumber(getLinkTotalVisitors(content)) }}
            </td>
            <td class="py-4 text-center w-[12.5%] text-[12px]">
              {{ commaSeparatedNumber(getLinkUniqueVisitors(content)) }}
            </td>
            <td class="py-4 text-center w-[12.5%] text-[12px]">
              {{ commaSeparatedNumber(getLinkTotalClicksAndConversions(content)) }}
            </td>
            <td class="py-4 text-center w-[12.5%] text-[12px]">
              {{ getLinkConversionRate(content) }}%
            </td>
            <td class="py-4 text-center w-[12.5%] ">
              <p class="text-[12px] font-poppins">{{
                  content.created_at |
                    relativeCaptilize
                }}</p>
            </td>
            <td class="py-4 text-center w-[12.5%]"></td>
          </tr>

        </div>

      </div>
    </div>

  </div>
  <div v-else-if="bio_links.length === 0" >
    <tr>
    <td colspan="9" class="">
      <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
          getFilteredMessage('bio links', payload.search, 'All Time', payload.archive, [], [], [])
        }}</p>
    </td>
  </tr>
  </div>
</template>
<script>
export default ({
  data: function () {
    return {
      payload: {
        archive: false,
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
    }
  },
  props: {
    bio_links: []
  },
  mounted() {
  },
  computed: {},
  methods: {
    getLinkTotalLinks(link) {
      return (link.total_links) ? link.total_links : 0
    },
    getLinkTotalVisitors(link) {
      return (link.total_visitors) ? link.total_visitors : 0
    },
    getLinkUniqueVisitors(link) {
      return (link.unique_visitors) ? link.unique_visitors : 0
    },
    getLinkTotalClicksAndConversions(link) {
      return (link.total_clicks_and_conversions) ? link.total_clicks_and_conversions : 0
    },
    getLinkConversionRate(link) {
      return (link.conversion_rate) ? link.conversion_rate : 0
    }
  }
})
</script>

<style>
.truncate_url {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
